[data-theme-version="dark"] {
    &[data-layout="vertical"]{
		&[data-sidebar-position="fixed"]{
			.header{
				border-color:$d-border;
			}
		}
	}
    .header {
        background-color: $dark-card;
		border-color:$d-border;
    }

    .header-left {
        input {
            background: $dark-card!important;
            border-color: $d-border ;
            color: $white !important;

            &:focus {
                box-shadow: none;
            }

            &::placeholder {
                color: $white;
            }
        }
		.search_icon{
		    background: $d-bg !important;
			
			i{
				color: $white;
			}
		}
    }

    .header-right {
		.right-sidebar a{
			color:$primary;
			border-color:$d-border;
		}
		.header-profile > a.nav-link .header-info strong{
			color:$white;
		}
		.notification_dropdown .nav-link{
			background: transparent!important;
			.badge{
				border-color:$dark-card;
			}
		}
        .dropdown {
            .nav-link {
				color: $white;
                &:hover {
                    color: $white;
                }
            }
        }
		.nav-link {
		
		}	
        .notification_dropdown {
            .dropdown-item {
                a {
                    color: $white;
                }
            }
        }
    }
	.search-area {
		//border:1px solid $d-border !important;
		border-radius: 14px;
		align-items: center;
		.input-group-append .input-group-text{
			background: $d-bg;
			
		}
		.form-control{
			&:focus,&:hover,&:active{
				background: $d-bg;
			}
		}
	}
	.pulse-css{
		border-color:$dark-card;
	}
	.nav-header .hamburger .line{
		background:$white!important;
	}
	.menu-toggle .nav-header .nav-control .hamburger .line {
		background-color: $white !important;
	}
}