[data-theme-version="dark"] {
	.nav-header{
		border-color:$d-border;
	}
		
    .nav-header, 
    .nav-control {
        background-color:$dark-card;
    }

    .nav-control {
        color: $white;
    }

    .brand-logo {
        color: $white;

        &:hover {
            color: $white;
        }
    }
	.brand-title{
		path{
			fill:$white;
		}
		
	}
}